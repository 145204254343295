::selection {
  color: white;
  background: rgb(130, 184, 130);
}

.App {
  font-family: 'Roboto' !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-footer-wrapper {
  margin-top: 120px;
}

.App-footer {
  background-color: #00A86B;
  color: white;
  text-shadow: 1px 1px 1px black;
  position: fixed;
  bottom: 0;
  box-shadow: 0 20px 40px #00A86B;
  padding: 2px;
  text-align: center;
  width: 100%;
}

.App-header {
  align-items: center;
  background-color: #00A86B;
  border-bottom: 1px solid rgb(118, 202, 0);
  box-shadow: 0 0 10px #aaa;
  color: aliceblue;
  display: flex;
  font-size: calc(10px + 2vmin);
  font-weight: light;
  flex-direction: column;
  justify-content: center;
  min-height: 320px;
  text-shadow: 1px 1px 12px black;
  transition: background-color 1500ms;
}

.App-header.transparent {
  background-color: transparent !important;
}

.App-header h1 {
  color: white;
  font-family: 'Brush Script MT', cursive, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-style: italic;
  font-size: 50px;
  margin: 0;
  margin-top: 50px;
  line-height: 10px;
  text-align: center;
  line-height: 45px;
}

.App-header h2 {
  color: white;
  font-family: 'Brush Script MT', cursive, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-style: italic;
}

h1 {
  color: #00A86B;
  font-size: 30px;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error-description {
  max-width: 500px;
  margin: auto;
}

.home-text-wrapper {
  max-width: 90%;
  margin: auto;
  text-shadow: 1px 1px 1px #eee;
}

.home-text-wrapper h1 {
  text-shadow: 1px 1px 1px rgb(183, 230, 183);
}

.content-image {
  box-shadow: 2px 2px 20px silver;
  float: right;
  margin-left: 20px;
  margin-bottom: 10px;
  min-width: 200px;
  width: 25%;
}

.content-image-left {
  box-shadow: 2px 2px 20px silver;
  float: left;
  margin-right: 20px;
  margin-bottom: 10px;
  min-width: 200px;
  width: 25%;
}