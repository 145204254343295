.nav-bar {
  float: right;
  position: absolute;
  right: 8px;
  top: 20px;
}

.rsis-container div {
  background-position: center !important;
  background-size: auto !important;
  background-repeat: no-repeat !important;
}

.nav-bar a {
  color: white;
  background: rgba(0,0,0,0.4);
  text-decoration: solid;
  letter-spacing: 0;
  font-size: 14px;
  padding: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  position: relative;
  top: -16px;
  left: 0;
  margin-left: 5px;
  margin-right: 5px;
  text-shadow: 1px 1px 2px #777;
  box-shadow: 0 0 2px silver;
  border: 1px solid darkgreen;
  border-radius: 2px;
  font-weight: bold;
  transition: all 300ms;
  text-shadow: 1px 1px 2px #111;
}

.nav-bar a:hover {
  color: aliceblue;
  border: 1px solid green;
  box-shadow: 0 0 1px green;
}

.nav-bar a.active {
  color: #333 !important;
  border: 1px solid silver;
  background-color: aliceblue;
  box-shadow: 0 0 10px white;
  text-shadow: 1px 1px 2px #ddd;
}

@media only screen and (max-width: 600px) {
  .nav-bar a {
    font-size: 10px !important;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

@media only screen and (max-width: 500px) {
.nav-bar {
  position: inherit !important
}

    .nav-bar a {
      font-size: 10px !important;
      padding: 8px;
      margin-left: 1px !important;
    }
}

@media only screen and (max-width: 400px) {
  .nav-bar a {
    font-size: 10px !important;
    padding-top: 8px;
    padding-bottom: 8px;
    padding: 8px;
  }
}
